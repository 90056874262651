import './src/scss/custom.scss';

import React from 'react';
import { AuthProvider, TAuthConfig, TRefreshTokenExpiredEvent } from 'react-oauth2-code-pkce';
import fetch from 'isomorphic-fetch';
import { ApolloClient, ApolloProvider, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client';
import DefaultHelmet from './src/components/DefaultHelmet';

const addScript = (url, integrity) => {
  const script = document.createElement('script');
  script.src = url;
  script.async = false;
  script.integrity = integrity;
  script.crossOrigin = 'anonymous';
  document.body.appendChild(script);
};

const addScriptUrlOnly = (url) => {
  const script = document.createElement('script');
  script.src = url;
  script.defer = true;
  document.body.appendChild(script);
};

export const onClientEntry = () => {
  window.onload = () => {
    // Any scripts can be added with our addScript function here. This used to include the Bootstrap CDN css and JS
    // scripts, but imports from the react-bootstrap NPM module are now used instead.
  };
};

const isBrowser = () => typeof window !== 'undefined';

// Configuration documentation: https://www.npmjs.com/package/react-oauth2-code-pkce
//
// Environment variables are defined via
//   .env.development (gatsby develop)
//   .env.gamma (GAMMA=true gatsby build)
//   .env.production (gatsby build)
// The env vars necessary to be accessible in the browser need to be prefixed with GATSBY_
// https://www.gatsbyjs.com/docs/how-to/local-development/environment-variables
const authConfig: TAuthConfig = {
  clientId: process.env.GATSBY_AUTH_CLIENT_ID ?? '',
  autoLogin: false, // Prefer to call login() function instead of auto-redirect to login.
  storage: 'local', // If set to session, no login state is persisted by react-oauth2-code-pkce when the browser closes.

  // Not the /oauth2/authorize endpoint. Instead, the /login hosted UI endpoint.
  authorizationEndpoint: process.env.GATSBY_AUTH_AUTHORIZATION_ENDPOINT ?? '',
  scope: 'aws.cognito.signin.user.admin email openid phone profile',
  redirectUri: process.env.GATSBY_AUTH_REDIRECT_URI ?? '',
  // Use gatsby navigate to have the client navigate to the Single Page Application (SPA) route. This is nice as we
  // don't need to allowlist the redirect_uri in the auth app client.
  // Should not use reach router's navigate: https://github.com/gatsbyjs/gatsby/issues/7643
  //
  // Update: No longer navigating upon login in case the logged in user is not admin, in which case the normal user
  // would be viewing the NotFoundAppPage.
  postLogin: () => {
    // navigate('/login-info');
  },

  tokenEndpoint: process.env.GATSBY_AUTH_TOKEN_ENDPOINT ?? '',
  decodeToken: true,

  // Not the /oath2/revoke endpoint. Instead, the /logout hosted UI endpoint.
  logoutEndpoint: process.env.GATSBY_AUTH_LOGOUT_ENDPOINT ?? '',
  // Causes addition of post_logout_redirect_uri, but Cognito does not recognize it. Instead, use extraLogoutParameters
  // with logout_uri specified: https://docs.aws.amazon.com/cognito/latest/developerguide/logout-endpoint.html
  logoutRedirect: process.env.GATSBY_AUTH_LOGOUT_REDIRECT ?? '',
  extraLogoutParameters: { logout_uri: process.env.GATSBY_AUTH_LOGOUT_REDIRECT ?? '' },

  // The library must have the access token and refresh token expiration duration hardcoded, because it relies on
  // the token response including the fields "expires_in" and "refresh_expires_in" or "refresh_token_expires_in".
  // However, Cognito's /token response only has the expires_in field and not the refresh expiry, so only the access
  // token expiry is understood by the library, but not the refresh token expiry. Therefore, the library uses the
  // correct access token expiration (currently 1 hour) and defaults to a refresh token expiration duration of that plus
  // 10 minutes (70 minutes) if the values are not hardcoded here. Without the hardcoding, the refresh token would be
  // able to be used once, resulting in a total access time of around 2 hours before onRefreshTokenExpire is called.
  // Note that, as expected, exchanging the refresh token for access and ID tokens does not cause refresh_token to be
  // returned, hence the refresh token expiration timestamp does not get extended.
  //
  // https://github.com/soofstad/react-oauth2-pkce/issues/114
  // https://github.com/soofstad/react-oauth2-pkce/blob/0a2d0ae77e86ed4be29b8c93d4017e4e9ad9d59b/src/AuthContext.tsx#L109
  // https://github.com/soofstad/react-oauth2-pkce/blob/0a2d0ae77e86ed4be29b8c93d4017e4e9ad9d59b/src/timeUtils.ts#L24
  // https://docs.aws.amazon.com/cognito/latest/developerguide/token-endpoint.html
  //
  // tokenExpiresIn: 60 * 60, // 1 Hour // Not necessary since Cognito's token response includes expires_in.
  refreshTokenExpiresIn: 30 * 24 * 60 * 60, // 30 Days // Cognito's token response does not include refresh expiry.
  onRefreshTokenExpire: (event: TRefreshTokenExpiredEvent) =>
    window.confirm('Please login again to continue using the site.') && event.login(),
};

// Use an HttpLink to configure the ApolloClient with a link rather than just a uri. The HttpLink enables us to
// attach the Authorization header for the JWT for auth.
// https://medium.com/risan/set-authorization-header-with-apollo-client-e934e6517ccf
const httpLink = new HttpLink({ uri: process.env.GATSBY_HTTP_LINK_URI ?? '' });
const authLink = new ApolloLink((operation, forward) => {
  // Get the JWT auth token.
  //
  // Access the localStorage key managed by react-oauth2-pkce directly.
  // https://github.com/soofstad/react-oauth2-pkce/blob/main/src/AuthContext.tsx#L38
  //
  // TODO: Figure out a less fragile way to do this.
  const token = JSON.parse(localStorage.getItem('ROCP_token') ?? 'null');

  // Use the setContext method to set the HTTP headers.
  operation.setContext({ headers: { authorization: token ? `Bearer ${token}` : '' } });

  // Call the next link in the middleware chain.
  return forward(operation);
});
// Set up ApolloClient based on copying approach of gatsby-plugin-apollo:
// https://github.com/trevorblades/gatsby-plugin-apollo/tree/main
const apolloClient = new ApolloClient({
  // Otherwise fetch is not in the config type of ApolloClient. So including fetch isn't actually doing anything, but
  // include it just as a reminder that it used to be necessary. fetch is probably no longer necessary.
  ...({} as any),
  // Chain the authLink with the httpLink.
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  name: 'freestyle-search-admin-website',
  version: '1.0.0',
  fetch,
});

export const wrapRootElement = ({ element }, options) => {
  // The argument "options" is for configuration via gatsby-config.ts options, but I'm preferring not to
  // define options in gatsby-config.ts. That would require creating a local plugin in the
  // public/src/plugins/my-local-plugin directory.

  // react-oauth2-code-pkce uses localStorage or sessionStorage, which is not compatible with SSR and so must only be
  // for browser.
  if (isBrowser()) {
    return (
      <AuthProvider authConfig={authConfig}>
        <ApolloProvider client={apolloClient}>{element}</ApolloProvider>
      </AuthProvider>
    );
  } else {
    // If this page is being static site generated (SSG), then just return the desired initial load page directly
    // rather than having the page with path '/[...]' go through the Reach Router.
    //
    // For displaying nothing, use a blank full height div since our html background is blue.
    return (
      <div>
        <DefaultHelmet />
        <div style={{ minHeight: '100vh' }}></div>;
      </div>
    );

    // Previously, we used to return element in this case, which would indirectly end up going through the Reach Router
    // to render the NotFoundAppPage.
    //
    // Gatsby ends up doing static site generation (SSG) for this page [...].tsx. For that SSG, the path is
    // "/[...]", which means that this Reach Router will match it to the NotFoundAppPage, since it's the
    // catch-all page for links like "/...". Therefore, the NotFoundAppPage will be briefly displayed on page
    // load before the Javascript/React loads for the real page. So the NotFoundAppPage should check if it's
    // being SSG'd based on the existence of "window", and render what we are fine with being briefly
    // displayed on page load.
  }
};
